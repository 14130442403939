import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';

import { accountProfilEPostPatientNumSelector, accountStateSelector } from 'state/account/account.selectors';
import { accountFetchOrderHistoryRoutine, accountFetchOrderLinesRoutine } from 'state/account/account.routines';

import PageLayout from 'components/layouts/page/page.layout';
import OrderInfo from 'components/order-info/order-info';

import BackgroundImage from 'ui-kit/background-image/background-image';
import BirdiAccordion from 'ui-kit/accordion/accordion';
import PageSection from 'ui-kit/page-section/page-section';
import Pagination from 'components/pagination/pagination';
import LoadingMessage from 'ui-kit/loading-message/loading-message';

import { cartSelector } from 'state/cart/cart.selectors';
import { openModal, closeModal } from 'state/birdi-modal/birdi-modal.reducers';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import './order-history.style.scss';
import { OrderInvoiceForm } from 'components/order-invoice/order-invoice-form.component';
import { getMainCart } from 'state/cart/cart.helpers';

const PAGE_SIZE = 10;

const OrderHistory = ({ data }: { data: GatsbyTypes.OrderHistoryDataQuery }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { orderHistory } = useSelector(accountStateSelector);
    const epostPatientNum = useSelector(accountProfilEPostPatientNumSelector);
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersFetched, setOrdersFetched] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const cartObject = useSelector(cartSelector);
    const cartOrderNumber = getMainCart(cartObject, epostPatientNum)?.Order?.orderHeader.orderNum;

    const hasNextPage = useMemo(() => orderHistory && orderHistory.length === PAGE_SIZE, [orderHistory]);

    // Filter for a list of orders that do not match the order currently in the cart.
    // If the order history or cart order number is not yet available, return an
    // empty array.
    const filteredOrders = useMemo(() => {
        if (ordersFetched && orderHistory && cartOrderNumber !== undefined) {
            // If the cart is empty, then cartOrderNumber will be null.
            if (cartOrderNumber === null) {
                return orderHistory;
            }

            return orderHistory.filter(function (order) {
                return order.epostOrderNum !== cartOrderNumber;
            });
        }

        // If orderHistory and the cart have not been loaded yet, then just
        // return null.
        return null;
    }, [orderHistory, cartOrderNumber, ordersFetched]);

    const showLoading = filteredOrders === null;

    const handleGetOrderLines = (orderNum: string) => {
        dispatch(
            accountFetchOrderLinesRoutine.trigger({
                epostOrderNum: orderNum
            })
        );
    };

    const handleNextPageClick = () => {
        setCurrentPage(currentPage + 1);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    const handlePrevPageClick = () => {
        const nextPage = currentPage - 1;
        setCurrentPage(nextPage < 1 ? 1 : nextPage);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const handleReportFormCancel = () => {
        dispatch(closeModal({}));
    };

    const handleReportClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'get-report-modal',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t(`modals.generateReport.title`)}
                        body={
                            <OrderInvoiceForm
                                handleMessagesFormCancel={handleReportFormCancel}
                                centeredButtons={true}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };
    // Order history can go all the way back to 2014. Since we're retrieving
    // paginated results, it doesn't hurt to go back further just to be safe.
    const fetchOrderHistory = useCallback(() => {
        // Reset the error and fetched state in case we're moving between pages.
        setFetchError(false);
        setOrdersFetched(false);

        // Fetch the orders.
        dispatch(
            accountFetchOrderHistoryRoutine.trigger({
                from: '01.01.2010',
                to: moment().endOf('year').format('MM.DD.YYYY'),
                page: currentPage.toString(),
                pageSize: PAGE_SIZE,
                onSuccess: () => setOrdersFetched(true),
                onFailure: () => setFetchError(true)
            })
        );
    }, [dispatch, currentPage]);

    useEffect(() => {
        fetchOrderHistory();
    }, [dispatch, currentPage, fetchOrderHistory]);

    return (
        <PageLayout metaData={{ nodeTitle: 'Order History' }}>
            <BackgroundImage image={data.blueSkyBackground}>
                <PageSection className="px-0 px-md-2">
                    <Row>
                        <Col
                            lg={{
                                span: 10,
                                offset: 1
                            }}
                        >
                            <div className="order-history">
                                <Row>
                                    <Col
                                        xl={{
                                            span: 10,
                                            offset: 1
                                        }}
                                    >
                                        <div className="order-history-header d-flex flex-column align-items-center px-3">
                                            <div className="h5 eyebrow-text">{t('pages.orderHistory.eyebrowText')}</div>
                                            <h1 className="h2">{t('pages.orderHistory.title')}</h1>
                                            <div className="spacer" />
                                            <button
                                                className="btn btn-outline-primary sm-full"
                                                onClick={handleReportClick}
                                            >
                                                {t('pages.orderHistory.getInvoice.buttons.openModal')}
                                            </button>
                                        </div>
                                        <BirdiAccordion className="order-history-accordion">
                                            <>
                                                {fetchError && (
                                                    <div className="h5 d-flex justify-content-center mb-5">
                                                        {t('pages.orderHistory.messages.fetchError')}
                                                    </div>
                                                )}
                                                {!fetchError && (
                                                    <>
                                                        <LoadingMessage
                                                            className="mb-5"
                                                            isVisible={showLoading}
                                                            text={t(
                                                                'pages.orderHistory.messages.retrievingOrderHistory'
                                                            )}
                                                        />
                                                        {filteredOrders &&
                                                            filteredOrders.length > 0 &&
                                                            filteredOrders.map((order) => {
                                                                return (
                                                                    <OrderInfo
                                                                        key={order.epostOrderNum}
                                                                        order={order}
                                                                        onGetOrderLines={handleGetOrderLines}
                                                                        translation={t}
                                                                        accordion={true}
                                                                    />
                                                                );
                                                            })}
                                                        {filteredOrders && filteredOrders.length === 0 && (
                                                            <div className="h5 d-flex justify-content-center mb-5">
                                                                {t('pages.orderHistory.messages.noOrderHistory')}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        </BirdiAccordion>
                                    </Col>
                                </Row>
                                <Row className="pb-5">
                                    <Col
                                        xl={{
                                            span: 10,
                                            offset: 1
                                        }}
                                    >
                                        <Pagination
                                            currentPage={currentPage}
                                            isNextPageDisabled={!hasNextPage}
                                            onNextPageClick={handleNextPageClick}
                                            onPreviousPageClick={handlePrevPageClick}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </PageSection>
            </BackgroundImage>
        </PageLayout>
    );
};

export default OrderHistory;

export const query = graphql`
    query OrderHistoryData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/blue-sky.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
