import * as yup from 'yup';
import { invoiceMinDate } from 'const/options';
export const ORDER_INVOICE_SCHEMA = (maxDate: string) =>
    yup.object().shape({
        startDate: yup
            .string()
            .required('Required')
            .test('Start Date', 'BelowMin', function (value) {
                return Date.parse(value) >= Date.parse(invoiceMinDate);
            })
            .test('Start Date', 'AboveMax', function (value) {
                return Date.parse(value) <= Date.parse(maxDate);
            }),
        endDate: yup
            .string()
            .required('Required')
            .test('End Date', 'BelowMin', function (value) {
                if (this.parent.startDate && value) {
                    return Date.parse(this.parent.startDate) <= Date.parse(value);
                }
            })
            .test('End Date', 'AboveMax', function (value) {
                return Date.parse(value) <= Date.parse(maxDate);
            })
    });
